/* eslint-disable jsx-a11y/alt-text */
import { makeStyles } from "@mui/styles";
import * as React from "react";

import {
  Grid,
  styled,
  Typography,
  Stepper,
  StepLabel,
  Box,
  Step,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";
import QRCode from "react-qr-code";
import LogoRow from "./LogoRow";
import BackToStart from "./BackToStart";
import SkipCoupon from "./SkipCoupon";
import UseCoupon from "./UseCoupon";

import AppStoreLogo from "../assets/apple.svg";
import PlayStoreLogo from "../assets/google2.png";
import Receipt from "./Receipt";
import SkipUseCoupon from "./SkipUseCoupon";
const BMWK = require("../assets/BMWK.jpg");
const IDUNION = require("../assets/IDunion.png");
const IDIDEAL = require("../assets/ID-Ideal.png");

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#F0803C",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#F0803C",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "grey",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "grey",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#F0803C",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#F0803C",
    zIndex: 1,
    fontSize: 40,
  },
  "& .QontoStepIcon-circle": {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));
const useStyles = makeStyles((theme) => ({
  MainContainer: {
    padding: "4vh 2vw 0 0",
    height: "91vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  Stepbar: {
    width: "100%",
    fontSize: "200%",
  },
  StepLabel: {
    color: "white",
  },
  qrcode: {
    textAlign: "center",
    marginTop: "1vh !important",
  },

  mainContent: {
    height: "fit-content",
    width: "100%",
    margin: "10px 1vw 10px 3vw",
    alignContent: "center",
  },

  icon: {
    height: "6rem",
    width: "6rem",
  },
}));
function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const MainPageCustomer = (props) => {
  const classes = useStyles();
  const steps = [
    "Verbindung mit dem Server herstellen" /* step 1: get Token */,
    "Verbindung mit BDR herstellen " /**   step 2:BDR State : invatation,                                      waiting for BDR State: response **/,
    "Digitalen Personalauweis erhalten",
    "Verbindung mit Vendor herstellen" /** step 3:BDR State : response,                                        waiting for Vendor State: response */,
    "Proof Request von Vendor akzeptieren" /** step 4:BDR State : active  ,Vendor State: response,                 waiting for Vendor State: active  **/,
    "Coupon erhalten" /** step 5 :Vendor State: active,BDR State: active  */,
    "Coupon einlösen" /** step 6 */,
  ];
  const alternativeSteps = [
    "Verbindung mit dem Server herstellen" /* step 0: get Token */,
    "Verbindung mit BDR herstellen " /**   step 1:BDR State : invatation,                                      waiting for BDR State: response **/,
    "Digitalen Personalauweis erhalten",
    "Verbindung mit Vendor herstellen" /** step 2:BDR State : response,                                        waiting for Vendor State: response */,
    "Abgeschlossen",
  ];
  const {
    process,
    setProcess,
    vendorQrCodeValue,
    bdrQrCodeValue,
    baseIdAttributes,
    customerWantCoupon,
    setCustomerWantCoupon,
    couponQrCodeValueTrigger,
    shoppingList,
  } = props;
  const onClickSkipCoupon = () => {
    setCustomerWantCoupon(false);
    setProcess(5);
  };
  const onClickUseCoupon = () => {
    setCustomerWantCoupon(true);
    setProcess(6);
  };
  return (
    <Grid container className={classes.MainContainer}>
      <Stepper
        style={{ display: customerWantCoupon ? "inherit" : "none" }}
        alternativeLabel
        activeStep={process}
        connector={<QontoConnector />}
        className={classes.Stepbar}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Stepper
        style={{ display: customerWantCoupon ? "none" : "inherit" }}
        alternativeLabel
        activeStep={5}
        connector={<QontoConnector />}
        className={classes.Stepbar}
      >
        {alternativeSteps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid className={classes.mainContent}>
        {process === 0 ? (
          <>
            <Typography
              align="center"
              style={{ fontSize: "1rem", margin: "0.5rem" }}
            >
              Zum durchlaufen der Demo benötigen Sie eine SSI Wallet, z.B. die
              esatus Wallet:
            </Typography>
            <Typography
              align="center"
              style={{ fontSize: "1rem", margin: "0.5rem" }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.esatus.wallet"
                target="_blank"
                rel="noreferrer"
              >
                <img src={PlayStoreLogo} width="150" height="49"></img>
              </a>
              <a
                href="https://apps.apple.com/de/app/esatus-wallet/id1496769057"
                target="_blank"
                rel="noreferrer"
              >
                <img src={AppStoreLogo} width="150" height="50"></img>
              </a>
            </Typography>
          </>
        ) : process === 1 ? (
          <>
            <Typography
              align="center"
              style={{ fontSize: "0.8rem", margin: "0.1rem" }}
            >
              Zum Durchlaufen der Demo benötigen Sie eine Hyper Ledger Indy fähige SSI Wallet, z.B. die
              esatus Wallet:
            </Typography>
            <Typography
              align="center"
              style={{ fontSize: "0.8rem", margin: "0.1rem" }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.esatus.wallet"
                target="_blank"
                rel="noreferrer"
              >
                <img src={PlayStoreLogo} width="150" height="49"></img>
              </a>
              <a
                href="https://apps.apple.com/de/app/esatus-wallet/id1496769057"
                target="_blank"
                rel="noreferrer"
              >
                <img src={AppStoreLogo} width="150" height="50"></img>
              </a>
            </Typography>
            <Typography
              align="center"
              style={{ fontSize: "0.9rem", margin: "0.5rem" }}
            >
              Als Erstes wollen nun wir Ihren Personalausweis digitalisieren und
              Ihnen in Form eines Credentials ausstellen. Scannen Sie dafür den
              angezeigten QR-Code mit der entsprechenden Funktion der Wallet.
              Dies verbindet Sie mit der Bundesdruckerei, damit diesen Ihnen das
              Perso-Credential ausstellen kann. Dafür wechseln Sie, nach
              erfolgreichem Aufbau der Verbindung, oben rechts zur Rolle der
              Bundesdruckerei. Nachdem Sie Ihren digitalen Personalausweis
              erhalten haben, navigieren Sie bitte zur Kundensicht zurück.
            </Typography>
            <Typography className={classes.qrcode}>
              <a href={bdrQrCodeValue}>
                <QRCode value={bdrQrCodeValue} size={350} />
              </a>
            </Typography>
          </>
        ) : process === 2 ? (
          <>
            <Typography
              align="center"
              style={{ marginTop: "3rem", fontSize: "2rem" }}
            >
              Sie erhalten nun Ihren Digitalen Personalausweis in Ihrer Wallet.
            </Typography>
            <Typography
              align="center"
              style={{ marginTop: "10rem", height: "2rem" }}
            >
              <BackToStart />
            </Typography>
          </>
        ) : process === 3 ? (
          <>
            <Typography
              align="center"
              style={{ marginTop: "0.5rem", fontSize: "1rem" }}
            >
              Sie befinden sich nun im Laden eines Händlers Ihrer Wahl. Hier
              werden Sie auf die Möglichkeit aufmerksam, sich mittels SSI bei
              dem Händler zu registrieren und Coupons in Form von Credentials
              ausgestellt zu bekommen. Außerdem haben Sie die Wahl, ob Sie
              Persönliche Daten im Austausch gegen Coupons/Rabattaktionen mit
              dem Händler teilen möchten. Um eine Verbindung mit dem Händler
              aufzubauen, scannen Sie den angezeigten QR Code, dann wechseln Sie
              oben rechts zur Händlersicht.
            </Typography>
            <Typography align="center" style={{ height: "2rem" }}></Typography>
            <Typography className={classes.qrcode}>
              <a href={vendorQrCodeValue}>
                <QRCode value={vendorQrCodeValue} size={350} />
              </a>
            </Typography>
          </>
        ) : process === 4 ? (
          <>
            <Typography
              align="center"
              style={{ marginTop: "3rem", fontSize: "2rem" }}
            >
              Bitte akzeptieren Sie nun den Proof Request in Ihrer Wallet, um
              den Coupon zu erhalten.
            </Typography>

            <Typography
              align="center"
              style={{ marginTop: "3rem", height: "2rem" }}
            >
              <SkipCoupon onClickSkipCoupon={onClickSkipCoupon} />
              <BackToStart />
            </Typography>
          </>
        ) : process === 5 && customerWantCoupon === true ? (
          <>
            <Typography
              align="center"
              style={{ marginTop: "3rem", fontSize: "2rem" }}
            >
              Sie werden jetzt ein Coupon vom Verkäufer erhalten.
            </Typography>
            <Typography
              align="center"
              style={{ marginTop: "3rem", height: "2rem" }}
            >
              <UseCoupon onClickUseCoupon={onClickUseCoupon} />
            </Typography>

            <Typography
              align="center"
              style={{ marginTop: "3rem", height: "2rem" }}
            >
              <BackToStart />
            </Typography>
          </>
        ) : process === 5 && customerWantCoupon === false ? (
          <>
            <Typography
              align="center"
              style={{ marginTop: "3rem", fontSize: "2rem" }}
            >
              Fertig
            </Typography>
            <Typography
              align="center"
              style={{ marginTop: "10rem", height: "2rem" }}
            >
              <BackToStart />
            </Typography>
          </>
        ) : process === 6 && customerWantCoupon === true ? (
          <>
            <Typography align="center" variant="h6" style={{ margin: "1rem" }}>
              Belegübersicht
            </Typography>
            <Grid
              container
              spacing={3}
              align="center"
              // style={{ height: "2rem" }}
            >
              <Grid
                item
                xs={6}
                style={{
                  width: "inherit",
                }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  style={{ margin: "1rem" }}
                >
                  Um das Coupon einzulösen, scannen Sie den angezeigten QR Code
                </Typography>
                <QRCode value={couponQrCodeValueTrigger} size={350} />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  maxWidth: "90vw",
                  maxHeight: "50vh",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Receipt rabatt={0} rows={shoppingList} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
              style={{ margin: "0.5rem", padding: "0.2rem" }}
            >
              <Grid item xs={6}>
                <BackToStart />
              </Grid>
              <Grid item xs={6}>
                <SkipUseCoupon />
              </Grid>
            </Grid>
          </>
        ) : process === 7 && customerWantCoupon === true ? (
          <>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ margin: "0", padding: "0" }}
            >
              <Grid item xs={6}>
                <Typography align="center" variant="h6">
                  Coupon erfolgreich eingelöst
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <BackToStart />
              </Grid>
            </Grid>

            <Typography
              align="center"
              variant="h6"
              style={{ margin: "2rem 2rem 0rem 2rem" }}
            >
              <Receipt rabatt={10} rows={shoppingList} />
            </Typography>
          </>
        ) : (
          <>
            <Typography
              align="center"
              style={{ marginTop: "15rem", fontSize: "200%" }}
            >
              Leider ist ein Fehler aufgetreten
            </Typography>
            <Typography
              align="center"
              style={{ marginTop: "10rem", height: "2rem" }}
            >
              <BackToStart />
            </Typography>
          </>
        )}
      </Grid>
      <LogoRow classes={classes} logo1={IDUNION} logo2={BMWK} logo3={IDIDEAL} />
    </Grid>
  );
};
export default MainPageCustomer;
