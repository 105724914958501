import React, { useState, useEffect, useLayoutEffect } from "react";
import { Box, Snackbar, Alert, Grid } from "@mui/material";
import "./App.css";
import HeaderCustomer from "./Components/HeaderCustomer";
import Footer from "./Components/Footer";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import background from "./assets/white_background_2.jpg";
import MainPageCustomer from "./Components/MainPageCustomer";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import urlBackend from "./config.json";

// STYLING
const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundImage: "url(" + background + ")",
    //backgroundColor: "rgb(248,248,248)",
  },
}));

// APP
const AppCustomer = () => {
  document.title = "Kundensicht - SSI Couponing Demo";
  const classes = useStyles();
  const [process, setProcess] = useState(0);
  const cookies = new Cookies();

  // QR CODES
  const [vendorQrCodeValue, setVendorQrCodeValue] = useState("");
  const [bdrQrCodeValue, setBdrQrCodeValue] = useState("");
  const [couponQrCodeValueTrigger, setCouponQrCodeValueTrigger] = useState("");

  // CONNECTION STATES
  const [stateVendorConnection, setStateVendorConnection] = useState("");
  const [stateBDRConnection, setStateBDRConnection] = useState("");
  const [
    stateVendorCouponConnectionTrigger,
    setStateVendorCouponConnectionTrigger,
  ] = useState("");

  //CONNECTION IDS
  const [vendorConnectionID, setVendorConnectionID] = useState("");
  const [bdrConnectionID, setBdrConnectionID] = useState("");
  const [couponVendorConnectionIDTrigger, setCouponVendorConnectionIDTrigger] =
    useState("");

  // CREDENTIAL STATES AND CRED_EX_IDS
  const [stateBaseIdCredential, setStateBaseIdCredential] = useState("");
  const [baseIdCredExID, setBaseIdCredExID] = useState("");
  const [stateCouponCredential, setStateCouponCredential] = useState("");
  const [couponCredExID, setCouponCredExID] = useState("");

  // BEARER TOKENS AND WALLET IDS
  const [tokenBDR, setTokenBDR] = useState("");
  const [tokenVendor, setTokenVendor] = useState("");
  const [bdrWalletID, setBdrWalletID] = useState("");
  const [vendorWalletID, setVendorWalletID] = useState("");

  // PROOF REQUEST STATES AND PRES_EX_IDS
  const [stateBaseIdProofRequest, setStateBaseIdProofRequest] = useState("");
  const [baseIdPresExID, setBaseIdPresExID] = useState("");
  const [stateCouponProofRequest, setStateCouponProofRequest] = useState("");
  const [couponPresExID, setCouponPresExID] = useState("");

  // PROOF REQUEST PRESENTATION ATTRIBUTES
  const [baseIdAttributesReceived, setBaseIdAttributesReceived] =
    useState(false);
  const [baseIdPresentationAttributes, setBaseIdPresentationAttributes] =
    useState({});
  // CHECK IF CUSTOMER WANT TO SKIP
  const [customerWantCoupon, setCustomerWantCoupon] = useState(true);

  // MISC
  const [openAlertError, setOpenAlertError] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  // shoppingList
  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit);
    return { desc, qty, unit, price };
  }

  const shoppingList = [
    createRow(
      "Kabellose Tastatur, cimetech USB Tastatur Flaches Design Business-Tastatur Wireless Keyboard geräuscharm, Ultra Slim Design mit Ziffernblock (Full Size QWERTZ Deutsches Layout - Turqouise)",
      1,
      84,
      23.99
    ),
    createRow(
      "Lenovo (FullHD 15,6 Zoll) Gaming Notebook (AMD Ryzen™ 5 5500U 12-Thread CPU, 4.0 GHz, 20GB DDR4, 1000 GB SSD, Radeon™, HDMI, BT, USB 3.0, WLAN, Windows 11 Prof. 64, MS Office)",
      2,
      24,
      599.9
    ),
    createRow(
      "KOORUI 22 Zoll Business Computer Monitor, Desktop Gaming Monitor, FHD 1080p, 75hz, Eye Comfort, sRGB 99% Farbumfangs, (Ultradünne Blende, HDMI, VGA, Neigbar, VESA 75x75), PC Bildschirm , Schwarz",
      2,
      33,
      103.9
    ),
    createRow(
      "Bildschirm-Ständer, höhenverstellbar, für Laptop und Bildschirme, mit rutschfesten Füßen",
      1,
      12,
      17.99
    ),
    createRow(
      "Kabellose Computer-Maus mit USB-Nano-Empfänger, Schwarz",
      2,
      9.99
    ),
    createRow(
      "HDMI-zu-DVI-Adapterkabel, -1,83 meter, (Nicht für den Anschluss an SCART- oder VGA-Anschlüsse)",
      2,
      6.99
    ),
  ];
  //MISC FUNCTIONS

  function getRandomShoppingList(count = 3) {
    const shuffled = shoppingList.sort(() => 0.5 - Math.random());

    return shuffled.slice(0, count);
  }

  const handleCloseErrorAxios = () => {
    setOpenAlertError(false);
  };

  const handleCloseSuccess = () => {
    setOpenAlertSuccess(false);
  };

  function getRandomNumber(amount) {
    let digits = "";
    for (let i = 0; i < amount; ++i) digits += Math.floor(Math.random() * 10);

    return digits;
  }

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  function randomDate(start, end) {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    ).toLocaleDateString();
  }

  function getRandomString(amount) {
    var chars = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (let i = 0; i < amount; i++) {
      chars += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return chars;
  }

  function getRandomGdti() {
    return getRandomNumber(13) + getRandomString(15);
  }

  //Check Cookies
  console.log(cookies.get("a"));

  /* Try to get Token, create Connection to ACA-PY */
  /* if (tokenBDR === "" || tokenVendor === "") {
  } */
  useEffect(() => {
    const getTokens = () => {
      if (tokenBDR === "" || tokenVendor === "") {
        axios
          .get(urlBackend.BACKEND_SERVER + "/getACAPYConnection")
          .then((response) => {
            setOpenAlertError(false);
            setOpenAlertSuccess(true);

            setTokenBDR(response.data.token_BDR);
            setTokenVendor(response.data.token_Vendor);
            setVendorWalletID(response.data.vendorWalletID);
            setBdrWalletID(response.data.bdrWalletID);
            console.log("reponse from /getACAPYConnection: ");
            console.log(response.data);
          })
          .catch((error) => {
            setOpenAlertError(true);
          });
      }
    };
    const getCurrentTokenFunction = setInterval(() => getTokens(), 5000);
    return () => clearInterval(getCurrentTokenFunction);
  }, [tokenBDR, tokenVendor]);

  // Get QRCodes
  useEffect(() => {
    if (
      process === 1 &&
      tokenVendor !== "" &&
      tokenBDR !== "" &&
      bdrWalletID !== "" &&
      vendorWalletID !== ""
    ) {
      var bodyFormData = new FormData();
      bodyFormData.append("tokenVendor", tokenVendor);
      bodyFormData.append("tokenBDR", tokenBDR);
      //bodyFormData.append("bdrWalletID", bdrWalletID);
      //bodyFormData.append("vendorWalletID", vendorWalletID);

      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/getInvitationURLs",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        setBdrQrCodeValue(response.data.qrcodeBDR);
        setVendorQrCodeValue(response.data.qrcodeVendor);
        setVendorConnectionID(response.data.connectionIDVendor);
        setBdrConnectionID(response.data.connectionIDBDR);
        console.log("response from /getInvitationURLs: ");
        console.log(response.data);
      });
    }
  }, [bdrWalletID, process, tokenBDR, tokenVendor, vendorWalletID]);
  // Init Meta Data for Vendor

  useEffect(() => {
    if (vendorConnectionID !== "" && vendorConnectionID != null) {
      var bodyFormData = new FormData();
      bodyFormData.append("vendorToken", tokenVendor);
      bodyFormData.append("vendorConnectionID", vendorConnectionID);

      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/vendor/initMetadata",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        console.log(response.data);
      });
    }
  }, [vendorConnectionID]);
  // Init Meta Data for Vendor
  // Get Connection States, Check each 3s
  useEffect(() => {
    const getCurrentConnectionState = () => {
      if (bdrConnectionID !== "") {
        var bodyFormDataVendor = new FormData();
        bodyFormDataVendor.append("vendorToken", tokenVendor);
        bodyFormDataVendor.append("vendorConnectionID", vendorConnectionID);
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/vendor/getCurrentStateVendor",
          data: bodyFormDataVendor,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          setStateVendorConnection(response.data);
          console.log("response from /vendor/getCurrentStateVendor: ");
          console.log(response.data);
        });
        var bodyFormDataBdr = new FormData();

        bodyFormDataBdr.append("tokenBDR", tokenBDR);
        bodyFormDataBdr.append("bdrConnectionID", bdrConnectionID);
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/bdr/getCurrentStateBDR",
          data: bodyFormDataBdr,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          setStateBDRConnection(response.data);
          console.log("response from /bdr/getCurrentStateBDR: ");
          console.log(response.data);
        });
      }
    };
    const getCurrentStateFunction = setInterval(
      () => getCurrentConnectionState(),
      3000
    );
    return () => clearInterval(getCurrentStateFunction);
  }, [bdrConnectionID, tokenBDR, tokenVendor, vendorConnectionID]);

  // Get BaseId Credential State, Check each 3s
  useEffect(() => {
    const getCurrentStateBaseIdCred = () => {
      // Get BaseId Cred Exchange ID
      if (baseIdCredExID === "") {
        //TODO if BaseID null
        var bodyFormDataBDR = new FormData();
        bodyFormDataBDR.append("token", tokenBDR);
        bodyFormDataBDR.append("connectionID", bdrConnectionID);
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/bdr/getMetadata",
          data: bodyFormDataBDR,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          if (
            response.data.metadata.baseID !== "" &&
            response.data.metadata.baseID !== null
          ) {
            setBaseIdCredExID(response.data.metadata.baseID);
          }
          console.log("response from /bdr/getMetadata: ");
          console.log("baseIdCredExID = " + response.data.metadata.baseID);
        });
      } else {
        // Get State of BaseId Credential with BaseId Credential Exchange ID
        var bodyFormDataBDR = new FormData();
        bodyFormDataBDR.append("tokenBDR", tokenBDR);
        bodyFormDataBDR.append("cred_ex_id", baseIdCredExID);
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/bdr/getCurrentStateBaseIdCred",
          data: bodyFormDataBDR,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          if (response.data.state !== "" && response.data.state !== null) {
            setStateBaseIdCredential(response.data.state);
          }
          console.log("response from /bdr/getCurrentStateBaseIdCred: ");
          console.log(response.data);
        });
      }
    };
    const getCurrentStateFunction = setInterval(
      () => getCurrentStateBaseIdCred(),
      3000
    );
    return () => clearInterval(getCurrentStateFunction);
  }, [baseIdCredExID, bdrConnectionID, tokenBDR]);

  // Get State of Vendors ProofRequest of BaseId Credential, Check each 3s
  useEffect(() => {
    const getCurrentStateBaseIdProofRequest = () => {
      // Get Presentation Exchange ID
      if (baseIdPresExID === "" && vendorConnectionID !== "") {
        //TODO if BaseID null
        var bodyFormDataVendor = new FormData();
        bodyFormDataVendor.append("token", tokenVendor);
        bodyFormDataVendor.append("connectionID", vendorConnectionID);
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/vendor/getMetadata",
          data: bodyFormDataVendor,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          if (
            response.data.metadata.proofRequestIDForBaseID !== "" &&
            response.data.metadata.proofRequestIDForBaseID !== null
          ) {
            setBaseIdPresExID(response.data.metadata.proofRequestIDForBaseID);
          }
          console.log("response from /vendor/getMetadata: ");
          console.log(response.data.metadata.proofRequestIDForBaseID);
        });
      } else if (baseIdPresExID !== "") {
        // Get State of ProofRequest with Presentation Exchange ID
        var bodyFormDataVendor = new FormData();
        bodyFormDataVendor.append("vendorToken", tokenVendor);
        bodyFormDataVendor.append("bdrToken", tokenBDR);
        bodyFormDataVendor.append("pres_ex_id", baseIdPresExID);
        axios({
          method: "post",
          url:
            urlBackend.BACKEND_SERVER +
            "/vendor/getCurrentStateProofRequestAndSaveInMetadata",
          data: bodyFormDataVendor,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          if (response.data.state !== "" && response.data.state !== null) {
            setStateBaseIdProofRequest(response.data.state);
          }

          console.log(
            "response from /vendor/getCurrentStateProofRequestAndSaveInMetadata: "
          );
          console.log(response.data);

          // get the Attributes from the Presentation, if the Presentation was recieved
          /* if (response.data.state === "presentation_received") {
            getBaseIdPresentationAttributes();
          } */
        });
      }
    };
    const getCurrentStateFunction = setInterval(
      () => getCurrentStateBaseIdProofRequest(),
      3000
    );
    return () => clearInterval(getCurrentStateFunction);
  }, [baseIdPresExID, tokenVendor, vendorConnectionID]);

  // Get Attributes from BaseId Presentation
  const getBaseIdPresentationAttributes = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("vendorToken", tokenVendor);
    bodyFormData.append("pres_ex_id", baseIdPresExID);
    axios({
      method: "post",
      url: urlBackend.BACKEND_SERVER + "/vendor/getAttributesFromPresentation",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      if (response.data !== "" && response.data !== null) {
        setBaseIdPresentationAttributes(response.data);
        setBaseIdAttributesReceived(true);
      }
      console.log("response from /vendor/getAttributesFromPresentation: ");
      console.log(response.data);
    });
  };
  useEffect(() => {
    const getCurrentStateCoupon = () => {
      // Get Presentation Exchange ID
      if (couponCredExID !== "") {
        //TODO if BaseID null
        var bodyFormDataVendor = new FormData();
        bodyFormDataVendor.append("tokenVendor", tokenVendor);
        bodyFormDataVendor.append("couponCredExID", couponCredExID);
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/vendor/getCurrentStateCouponCred",
          data: bodyFormDataVendor,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          setStateCouponCredential(response.data);
        });
      }
    };

    const getCurrentStateFunction = setInterval(
      () => getCurrentStateCoupon(),
      3000
    );
    return () => clearInterval(getCurrentStateFunction);
  }, [couponCredExID]);

  //** State Coupon */
  useEffect(() => {
    const getCurrentStateCouponTrigger = () => {
      console.log(
        "couponVendorConnectionIDTrigger: ",
        couponVendorConnectionIDTrigger
      );
      // Get Presentation Exchange ID
      if (couponVendorConnectionIDTrigger !== "") {
        var bodyFormDataVendor = new FormData();
        bodyFormDataVendor.append("vendorToken", tokenVendor);
        bodyFormDataVendor.append(
          "vendorCouponConnectionID",
          couponVendorConnectionIDTrigger
        );
        axios({
          method: "post",
          url:
            urlBackend.BACKEND_SERVER + "/vendor/getCurrentStateVendorCoupon",
          data: bodyFormDataVendor,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          setStateVendorCouponConnectionTrigger(response.data);
          console.log("response from /vendor/getCurrentStateVendorCoupon: ");
          console.log(response.data);
        });
      }
    };

    const getCurrentStateTriggerFunction = setInterval(
      () => getCurrentStateCouponTrigger(),
      3000
    );
    return () => clearInterval(getCurrentStateTriggerFunction);
  }, [couponVendorConnectionIDTrigger]);

  //** Create New Invitation URL for Coupon */
  useEffect(() => {
    if (process === 6) {
      var bodyFormData = new FormData();
      bodyFormData.append("tokenVendor", tokenVendor);
      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/getInvitationURLsForCoupon",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        setCouponQrCodeValueTrigger(response.data.qrcodeVendor);
        setCouponVendorConnectionIDTrigger(response.data.connectionIDVendor);
        console.log("response from /getInvitationURLsForCoupon: ");
        console.log(response.data);
      });
    }
  }, [process]);

  //** State Proof Request Coupon */
  useEffect(() => {
    const getCurrentStateProofRequestCoupon = () => {
      // Get Presentation Exchange ID
      if (
        stateCouponProofRequest !== "presentation_received" &&
        process === 6
      ) {
        //TODO if BaseID null
        var bodyFormDataVendor = new FormData();
        bodyFormDataVendor.append("vendorToken", tokenVendor);
        bodyFormDataVendor.append("pres_ex_id", couponPresExID);
        bodyFormDataVendor.append("bdrToken", tokenBDR);
        axios({
          method: "post",
          url:
            urlBackend.BACKEND_SERVER +
            "/vendor/getCurrentStateCouponProofRequest",
          data: bodyFormDataVendor,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          if (response.data !== "") setStateCouponProofRequest(response.data);
        });
      }
    };
    const getCurrentStateProofRequestCouponFunction = setInterval(
      () => getCurrentStateProofRequestCoupon(),
      3000
    );
    return () => clearInterval(getCurrentStateProofRequestCouponFunction);
  }, [couponPresExID, process, stateCouponProofRequest]);

  // Process Changes
  useEffect(() => {
    const handleSendProofRequest = () => {
      var bodyFormData = new FormData();
      bodyFormData.append("vendorToken", tokenVendor);
      bodyFormData.append("connectionID", vendorConnectionID);
      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/vendor/askForBaseIDProofRequest",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (response) {
          console.log(response);
        });
    };

    // Some Random User Data
    const firstNames = [
      "Petra",
      "Hans",
      "Conrad",
      "Sabine",
      "Karl",
      "Lisa",
      "Bernd",
      "Sarah",
      "Charlotte",
    ];
    const lastNames = [
      "Müller",
      "Gustavsson",
      "Lisbon",
      "Sennebrant",
      "Sonnenschein",
      "Meier",
      "Schwartz",
      "Baumer",
      "Luisenthal",
    ];
    const cities = ["Neuss", "Düsseldorf", "Essen", "Köln"];
    const streets = [
      "Schwerinstraße",
      "Berliner Allee",
      "Karlsstraße",
      "Winkelweg",
    ];
    const titles = ["Prof", "Dr", "-"];

    function plzPrefix(formData) {
      let city = formData.get("addressCity");
      switch (city) {
        case "Neuss":
          return 41;
        case "Köln":
          return 50;
        case "Düsseldorf":
          return 40;
        case "Essen":
          return 45;
        default:
          return 11;
      }
    }

    const handleSendBaseId = () => {
      var bodyFormData = new FormData();
      bodyFormData.append("addressCity", cities[getRndInteger(0, 4)]);
      bodyFormData.append("dateOfExpiry", "12.09.2025");
      bodyFormData.append(
        "addressZipCode",
        plzPrefix(bodyFormData).toString() + getRandomNumber(3)
      );
      bodyFormData.append("nationality", "german");
      bodyFormData.append("placeOfBirth", cities[getRndInteger(0, 4)]);
      bodyFormData.append("addressStreet", streets[getRndInteger(0, 4)]);
      bodyFormData.append("academicTitle", titles[getRndInteger(0, 3)]);
      bodyFormData.append(
        "dateOfBirth",
        randomDate(new Date(2001, 0, 1), new Date(1955, 0, 0))
      );
      bodyFormData.append("documentType", "Personalausweis");
      bodyFormData.append("birthName", "-");
      bodyFormData.append("firstName", firstNames[getRndInteger(0, 9)]);
      bodyFormData.append("addressCountry", "Germany");
      bodyFormData.append("familyName", lastNames[getRndInteger(0, 9)]);
      bodyFormData.append("bdrToken", tokenBDR);
      bodyFormData.append("connectionID", bdrConnectionID);

      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/bdr/sendBaseId",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (response) {
          console.log(response);
        });
    };
    const handleSendCoupon = () => {
      let randomGDTI = getRandomGdti();
      var bodyFormData = new FormData();
      bodyFormData.append("vendorToken", tokenVendor);
      bodyFormData.append(
        "coupon-id",
        "https://vc-prototyping.gs1-germany.de/digital-link/253/" + randomGDTI
      );
      bodyFormData.append("rabattPercent", "10");
      bodyFormData.append("warengruppe", "Auf das ganze Sortiment!");
      bodyFormData.append(
        "gueltigkeitsdauer",
        "Gütltig bis " +
          new Date(
            new Date().getFullYear(),
            new Date().getUTCMonth() + 1,
            new Date().getUTCDate()
          ).toLocaleDateString()
      );
      bodyFormData.append("einloesebedingung", "");
      bodyFormData.append("connection-id", vendorConnectionID);

      bodyFormData.append("einloesebedingung", "Keine");

      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/vendor/sendCoupon",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          console.log(response.data.results.theLastCouponID.id);
          setCouponCredExID(response.data.results.theLastCouponID.id);
        })
        .catch((error) => {});
    };
    console.log("stateBDR=" + stateBDRConnection);
    console.log("stateVendor=" + stateVendorConnection);
    console.log("process=" + process);
    if (process === 0 && tokenVendor !== "" && tokenBDR !== "") {
      setProcess(1);
    }
    if (process === 1 && (stateBDRConnection === "response" || stateBDRConnection === "active")) {
      setProcess(2);
      handleSendBaseId();
    } else if (process === 2 && stateBaseIdCredential === "credential_issued") {
      setProcess(3);
    } else if (process === 3 && (stateVendorConnection === "response" || stateVendorConnection === "active")) {
      setProcess(4);
      handleSendProofRequest();
    } else if (
      process === 4 &&
      stateBaseIdProofRequest === "presentation_received" /*  &&
      baseIdAttributesReceived */
    ) {
      setProcess(5);
      handleSendCoupon();
    } else if (
      process === 5 &&
      stateCouponCredential === "credential_issued" &&
      customerWantCoupon
    ) {
      setProcess(6);
    }
    console.log(
      "stateVendorCouponConnectionTrigger",
      stateVendorCouponConnectionTrigger
    );
    if (process === 6 && (stateVendorCouponConnectionTrigger === "response"||stateVendorCouponConnectionTrigger === "active")) {
      // setProcess(7);
    }
    if (process === 6 && stateCouponProofRequest === "presentation_received") {
      setProcess(7);
    }
  }, [
    bdrConnectionID,
    process,
    stateBaseIdCredential,
    stateBDRConnection,
    stateVendorConnection,
    stateCouponCredential,
    stateBaseIdProofRequest,
    baseIdAttributesReceived,
    tokenBDR,
    tokenVendor,
    vendorConnectionID,
    customerWantCoupon,
    getRandomGdti,
    stateVendorCouponConnectionTrigger,
    stateCouponProofRequest,
    couponVendorConnectionIDTrigger,
  ]);
  useEffect(() => {
    const handleSendProofRequestForCoupon = () => {
      var bodyFormData = new FormData();
      bodyFormData.append("vendorToken", tokenVendor);
      bodyFormData.append("connectionID", couponVendorConnectionIDTrigger);
      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/vendor/askForCouponIDProofRequest",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response.data !== "") setCouponPresExID(response.data);
        })
        .catch(function (response) {
          console.log(response);
        });
    };
    if (process === 6 && (stateVendorCouponConnectionTrigger === "response" || stateVendorCouponConnectionTrigger === "active")) {
      handleSendProofRequestForCoupon();
    }
  }, [process, stateVendorCouponConnectionTrigger]);
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.root}>
      <HeaderCustomer process={process} />
      <MainPageCustomer
        process={process}
        setProcess={setProcess}
        vendorQrCodeValue={vendorQrCodeValue}
        couponQrCodeValueTrigger={couponQrCodeValueTrigger}
        bdrQrCodeValue={bdrQrCodeValue}
        baseIdAttributes={baseIdPresentationAttributes}
        customerWantCoupon={customerWantCoupon}
        setCustomerWantCoupon={setCustomerWantCoupon}
        shoppingList={getRandomShoppingList(4)}
      />
      <Snackbar
        open={openAlertError}
        autoHideDuration={3000}
        onClose={handleCloseErrorAxios}
        style={{ marginLeft: "40vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseErrorAxios}
          severity="error"
          sx={{ width: "fit-content", fontFamily: "monospace" }}
        >
          Fehler beim Verbinden mit dem Server!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
        style={{ fontFamily: "monospace" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Erfolgreich mit dem Server verbunden!
        </Alert>
      </Snackbar>
      <Footer />
    </Box>
  );
};

export default AppCustomer;
